<template>
  <div 
    v-tooltip.bottom="tooltip"
  >
    <a-button
      variant
      size="sm"
      class="bg-gray-200 text-gray-500 w-full"
      disabled
    >
      Meeting Ended
    </a-button>
  </div>
</template>

<script>
import { get } from '@/utils/lodash';

export default {
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  computed: {
    type() {
      return get(this.meeting, 'type');
    },
    title() {
      return get(this.type, 'title');
    },
    tooltip() {
      return `${this.title} has ended. you are no longer able to join.`;
    },
  }
};
</script>

