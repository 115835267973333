<template>
  <div>
    <a-avatar-stack class="inline-flex">
      <attendee
        v-for="attendee in attendees"
        :key="attendee.id"
        :attendee="attendee"
      />
      <a-avatar
        v-if="max > 0 && total > 0"
        v-tooltip.bottom="additionalAttendeesPlaceholder"
        size="sm"
        round="full"
        class="ring-2 ring-white"
        placeholder-class="bg-primary"
      >
        <template #placeholder>
          <span class="text-sm leading-none text-white">+{{ total }}</span>
        </template>
      </a-avatar>
    </a-avatar-stack>
  </div>
</template>

<script>
import { size, slice } from '@/utils/lodash';
import Attendee from './attendee.vue';
export default {
  components: {
    Attendee
  },
  props: {
    meeting: {
      type: Object,
      required: true
    },
    max: {
      type: Number,
      default: 0
    }
  },
  computed: {
    attendees() {
      return this.max > 0
        ? slice(this.meeting.attendees, 0, this.max)
        : this.meeting.attendees;
    },
    total() {
      return size(this.meeting.attendees) - this.max;
    },
    additionalAttendeesPlaceholder() {
      return `+${this.total} attendees`;
    }
  }
};
</script>