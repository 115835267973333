<template>
  <div class="text-sm leading-6 text-gray-500">
    {{ meeting.description }}
  </div>
</template>

<script>
export default {
  props: {
    meeting: {
      type: Object,
      required: true
    }
  }
};
</script>