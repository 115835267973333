<template>
  <a-card>
    <div
      class="p-6 pb-2 lg:pb-4"
      :class="{'opacity-50': isOver && !hasRecording}"
    >
      <div class="flex flex-col lg:flex-row justify-end lg:justify-between">
        <div class="w-full lg:w-9/12 space-y-2">
          <meeting-title :meeting="meeting" />
          <meeting-description
            v-if="hasDescription"
            :meeting="meeting"
          />
          <meeting-host
            v-if="hasHost"
            :meeting="meeting"
          />
        </div>
        <div class="my-4 mb-0 lg:my-0">
          <action-button
            :meeting="meeting"
            :schedule="schedule"
          />
        </div>
      </div>
      <div class="flex flex-row justify-end lg:justify-between mb-2 lg:mb-0">
        <div class="flex-grow w-8/12 space-y-2">
          <div class="flex flex-col lg:flex-row space-y-4 lg:space-y-0 pt-6">
            <div class="flex-grow">
              <div class="flex flex-col lg:flex-row lg:items-center justify-space space-y-2 lg:space-y-0 lg:space-x-4">
                <meeting-date
                  :meeting="meeting"
                  class="min-w-0 space-y-3"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="my-4 mb-2 lg:my-0">
          <panelists
            v-if="hasPanelists"
            :meeting="meeting"
            class="mt-4"
          />
          <attendees
            v-if="showAttendees"
            :meeting="meeting"
            class="mt-4"
            :max="5"
          />
        </div>
      </div>
    </div>
    <div v-if="debug">
      <debug
        v-if="debug"
        :meeting="meeting"
        class="p-2"
      />
    </div>
  </a-card>
</template>

<script>
import Debug from './debug.vue';
import MeetingDate from './date.vue';
import MeetingHost from './host.vue';
import MeetingTitle from './title.vue';
import MeetingDescription from './description.vue';
import Panelists from './panelists/panelists.vue';
import Attendees from './attendees/attendees.vue';
import ActionButton from './action-button.vue';
import { isEmpty, get } from '@/utils/lodash';
import { mapState } from 'vuex';
import dayjs from '@/utils/dates';
import now from '@/utils/now';
export default {
  components: {
    Debug,
    Attendees,
    Panelists,
    MeetingDate,
    MeetingHost,
    MeetingTitle,
    ActionButton,
    MeetingDescription
  },
  props: {
    meeting: {
      type: Object,
      required: true
    },
    schedule: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('debug', ['debug']),
    slot() {
      return get(this.meeting, 'slot');
    },
    attendees() {
      return get(this.meeting, 'attendees');
    },
    end() {
      return get(this.slot, 'end');
    },
    now() {
      return now.value;
    },
    isOver() {
      return this.now.subtract(5, 'minutes').isAfter(dayjs(this.end));
    },
    showAttendees() {
      return this.isPrivateMeeting && !isEmpty(this.attendees);
    },
    hasHost() {
      return !isEmpty(this.meeting.host);
    },
    hasDescription() {
      return !isEmpty(this.meeting.description);
    },
    type() {
      return get(this.meeting, 'type.key');
    },
    isSeminar() {
      return this.type === 'seminar';
    },
    isPrivateMeeting() {
      return this.type === 'meeting';
    },
    hasPanelists() {
      return this.isSeminar && !isEmpty(get(this.meeting, 'panelists'));
    },
    hasRecording() {
      return !isEmpty(this.meeting.video);
    },
  }
};
</script>