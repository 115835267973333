<template>
  <div class="space-y-2">
    <a
      v-if="hasZoom"
      :href="link"
      class="join-button"
      :disabled="disabled"
      target="_blank"
    >
      <slot>{{ label }}</slot>
    </a>
    <div 
      v-else
      v-tooltip.bottom="invalidMeetingTooltip"
    >
      <a-button
        variant
        size="sm"
        class="bg-gray-200 text-gray-500 w-full"
        disabled
      >
        Invalid Meeting
      </a-button>
    </div>
    <div
      v-if="password"
      class="flex justify-between w-1/3 lg:w-full px-4 py-2 rounded-md text-sm leading-5 bg-red-200 text-red-800"
    >
      <a-icon
        name="key"
        class="text-red-800"
      />
      <span>{{ password }}</span>
    </div>
  </div>
</template>

<script>
import { isEmpty, get } from '@/utils/lodash';
import { mapGetters } from 'vuex';
export default {
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('auth', ['id']),
    type() {
      return get(this.meeting, 'type');
    },
    title() {
      return get(this.type, 'title');
    },
    label() {
      return this.isHost ? `Start ${this.title}` : `Join ${this.title}`;
    },
    isHost() {
      return get(this.meeting, 'isHost');
    },
    link() {
      if (this.isHost && this.startMeeting) {
        return this.startMeeting;
      }

      return this.joinMeeting;
    },
    startMeeting() {
      return get(this.zoomMeeting, 'start');
    },
    joinMeeting() {
      return get(this.zoomMeeting, 'join');
    },
    zoomMeeting() {
      return get(this.meeting, 'meeting');
    },
    password() {
      return get(this.zoomMeeting, 'password');
    },
    disabled() {
      return isEmpty(this.zoomMeeting) || isEmpty(this.link);
    },
    hasZoom() {
      return !isEmpty(this.zoomMeeting);
    },
    invalidMeetingTooltip() {
      return 'Seems like this meeting is missing the meeting id and thus the link to be able to join this meeting. Please use the support chat at the bottom left of this page to report this issue.';
    }
  }
};
</script>

<style lang="scss" scoped>
.join-button {
  @apply flex justify-center w-full py-2 px-4 text-sm font-medium rounded-md transition duration-150 ease-in-out text-white bg-primary hover:bg-primary-active focus:outline-none focus:border-white focus:shadow-outline-gray;

  &--disabled,
  [disabled] {
    @apply cursor-not-allowed opacity-75;
  }
}
</style>
