<template>
  <a-button
    v-tooltip.bottom="tooltip"
    variant="danger"
    size="sm"
    class="w-full"
    :disabled="loading"
    :loading="loading"
    v-bind="$attrs"
    @click="onClick"
  >
    <slot>Remove from Schedule</slot>
  </a-button>
</template>

<script>
import { error } from '@/utils/helpers';
import { mapActions } from 'vuex';
import { get } from '@/utils/lodash';
export default {
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    type() {
      return get(this.meeting, 'type');
    },
    title() {
      return get(this.type, 'title');
    },
    tooltip() {
      return `Click to remove this ${this.title} from your schedule.`;
    }
  },
  methods: {
    ...mapActions('user', ['fetchSchedule']),
    ...mapActions('schedule', ['unsubscribe', 'all']),
    async onClick() {
      this.loading = true;
      try {
        await this.unsubscribe({ id: this.meeting.id, type: this.type.key });
        await this.all();
        await this.fetchSchedule();
      } catch (e) {
        this.$notify('danger', { html: error(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>