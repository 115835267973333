<template>
  <div class="text-base leading-6 font-medium text-black mb-1">
    {{ meeting.title }}
  </div>
</template>

<script>
export default {
  props: {
    meeting: {
      type: Object,
      required: true
    }
  }
};
</script>