<template>
  <div>
    <button-recording
      v-if="hasRecording"
      :meeting="meeting"
    />
    <button-ended
      v-else-if="isOver"
      :meeting="meeting"
    />
    <button-join
      v-else-if="isInProgress"
      :meeting="meeting"
    />
    <button-future
      v-else-if="isFutureMeeting && isSubscribed"
      :meeting="meeting"
    />
    <button-add
      v-else-if="canBeAdded"
      :meeting="meeting"
    />
    <button-remove
      v-else-if="canBeRemoved"
      :meeting="meeting"
    />
  </div>
</template>

<script>
import ButtonEnded from './buttons/ended.vue';
import ButtonJoin from './buttons/join.vue';
import ButtonFuture from './buttons/future.vue';
import ButtonAdd from './buttons/add.vue';
import ButtonRemove from './buttons/remove.vue';
import ButtonRecording from './buttons/recording.vue';
import dayjs from '@/utils/dates';
import now from '@/utils/now';
import { get, isEmpty } from '@/utils/lodash';
import { mapGetters } from 'vuex';
export default {
  components: {
    ButtonAdd,
    ButtonJoin,
    ButtonEnded,
    ButtonRemove,
    ButtonFuture,
    ButtonRecording
  },
  props: {
    meeting: {
      type: Object,
      required: true
    },
    schedule: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('auth', ['isExhibitor']),
    ...mapGetters('user', ['subscribed']),
    slot() {
      return get(this.meeting, 'slot');
    },
    start() {
      return get(this.slot, 'start');
    },
    end() {
      return get(this.slot, 'end');
    },
    now() {
      return now.value;
    },
    isOver() {
      return this.now.subtract(5, 'minutes').isAfter(dayjs(this.end));
    },
    isInProgress() {
      return this.now.isBetween(
        dayjs(this.start).subtract(5, 'minutes'),
        dayjs(this.end).add(5, 'minutes').add(1, 'seconds')
      );
    },
    isFutureMeeting() {
      return (
        (this.isMeeting || this.isPresentation) &&
        !this.canBeRemoved &&
        !this.isOver &&
        !this.isInProgress
      );
    },
    type() {
      return get(this.meeting, 'type');
    },
    isMeeting() {
      return get(this.type, 'key') === 'meeting';
    },
    isPresentation() {
      return get(this.type, 'key') === 'presentation';
    },
    canBeAdded() {
      return !this.schedule && !this.isMeeting;
    },
    isSubscribed() {
      return !isEmpty(this.subscribed(this.meeting.id));
    },
    canBeRemoved() {
      return this.schedule && !this.isMeeting && this.isSubscribed;
    },
    hasRecording() {
      return !isEmpty(this.meeting.video);
    }
  }
};
</script>