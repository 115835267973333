<template>
  <div class="text-base leading-5 text-gray-400">
    <a-avatar-stack class="inline-flex">
      <panelist
        v-for="panelist in panelists"
        :key="panelist.id"
        :panelist="panelist"
      />
    </a-avatar-stack>
  </div>
</template>

<script>
import Panelist from './panelist.vue';
export default {
  components: {
    Panelist
  },
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  computed: {
    panelists() {
      return this.meeting.panelists;
    }
  }
};
</script>