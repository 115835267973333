<template>
  <div 
    v-tooltip.bottom="tooltip"
  >
    <a-button
      variant="white"
      size="sm"
      class="w-full"
      disabled
    >
      Future Meeting
    </a-button>
  </div>
</template>

<script>
import { get } from '@/utils/lodash';
export default {
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  computed: {
    type() {
      return get(this.meeting, 'type');
    },
    title() {
      return get(this.type, 'title');
    },
    tooltip() {
      return `${this.title} is in the future. Joining this meeting will become available closer to the meeting start date.`;
    }
  }
};
</script>