<template>
  <div
    v-if="hasHost"
    class="text-base leading-5 text-gray-300"
  >
    Hosted by {{ host }}
  </div>
</template>

<script>
import { get, isEmpty } from '@/utils/lodash';
export default {
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  computed: {
    hasHost() {
      return !isEmpty(this.host);
    },
    host() {
      return get(this.meeting, 'host.fullname');
    }
  }
};
</script>