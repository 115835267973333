import { ref} from 'vue';
import dayjs from "./dates";

const now = ref(dayjs());
window.now = now;
window.dayjs = dayjs;

setInterval(() => {
  now.value = dayjs();
}, 1000);

export default now;