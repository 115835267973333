<template>
  <div>
    <a-button
      size="xs"
      variant="white"
      @click="visible = !visible"
    >
      <a-icon
        size="xs"
        name="eye"
      />
    </a-button>
    <pre v-if="visible">{{ meeting }}</pre>
  </div>
</template>

<script>
export default {
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    visible: false
  })
};
</script>