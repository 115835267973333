<template>
  <div 
    class="space-y-2"
  >
    <a-button
      size="sm"
      class="w-full"
      @click="playRecording"
    >
      <a-icon
        name="play"
        class="mr-1"
      /> Play Recording
    </a-button>
  </div>
</template>

<script>
export default {
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  created() {
    console.log(this.meeting);
  },
  methods: {
    playRecording() {
      if (this.meeting.type.key === 'seminar') {
        return this.$router.push({
          name: 'seminars.recordings.view',
          params: { meeting: this.meeting.id }
        });
      }

      return this.$router.push({
        name: 'schedule.recordings.view',
        params: { meeting: this.meeting.id }
      });
    }
  }
};
</script>

