<template>
  <a-avatar
    v-tooltip.bottom="tooltip"
    v-bind="$attrs"
    size="sm"
    :source="source"
    round="full"
    class="ring-2 ring-white"
  >
    <template 
      v-if="!hasSource"
      #placeholder
    >
      <slot name="placeholder">
        <span class="text-lg leading-none text-white">{{ initials }}</span>
      </slot>
    </template>
  </a-avatar>
</template>

<script>
import { resize } from '@/utils/resize';
import { get, isEmpty } from '@/utils/lodash';
export default {
  props: {
    attendee: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      default: 500
    },
    height: {
      type: Number,
      default: 500
    }
  },
  computed: {
    initials() {
      return this.attendee.initials;
    },
    tooltip() {
      let tooltip = this.attendee.fullname;

      if (!isEmpty(this.attendee.company)) {
        tooltip += ` (${this.attendee.company})`;
      }

      return tooltip;
    },
    hasSource() {
      return !isEmpty(this.source);
    },
    source() {
      let image = get(this.attendee, 'image.path');

      if (!image) {
        return '';
      }

      return resize(image, this.width, this.height);
    }
  }
};
</script>