<template>
  <a-button
    v-tooltip.bottom="tooltip"
    variant="secondary"
    size="sm"
    class="w-full"
    :class="{'opacity-50': added}"
    :loading="loading"
    v-bind="$attrs"
    @click="onClick"
  >
    <slot>{{ added ? 'Added to Schedule' : 'Add to my Schedule' }}</slot>
  </a-button>
</template>

<script>
import { error } from '@/utils/helpers';
import { mapActions, mapGetters } from 'vuex';
import { isEmpty, get } from '@/utils/lodash';
export default {
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters('user', ['subscribed']),
    type() {
      return get(this.meeting, 'type');
    },
    title() {
      return get(this.type, 'title');
    },
    added() {
      return !isEmpty(this.subscribed(this.meeting.id));
    },
    tooltip() {
      return this.added
        ? `Link to join the ${this.title} will be avaialbe a few minutes prior to the scheduled start date.`
        : `Click here to add this ${this.title} to your schedule`;
    }
  },
  methods: {
    ...mapActions('user', ['fetchSchedule']),
    ...mapActions('schedule', ['subscribe', 'all']),
    async onClick() {
      if (this.added) {
        return;
      }
      this.loading = true;
      try {
        await this.subscribe({ id: this.meeting.id, type: this.type.key });
        await this.all();
        await this.fetchSchedule();
      } catch (e) {
        this.$notify('danger', { html: error(e) });
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>