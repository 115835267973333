<template>
  <div
    class="text-sm leading-5 text-gray-400"
  >
    <a-icon
      name="clock"
      type="fas"
      size="sm"
      class="mr-1"
    />
    <span>
      <date-time
        :date="start"
        format="MMM D LT"
      />
      <span class="mx-1">-</span>
      <date-time :date="end" />
    </span>
  </div>
</template>

<script>
import DateTime from '@/components/ui/dates/date-time.vue';
export default {
  components: {
    DateTime
  },
  props: {
    meeting: {
      type: Object,
      required: true
    }
  },
  computed: {
    start() {
      return this.meeting.start;
    },
    end() {
      return this.meeting.end;
    }
  }
};
</script>